import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 解决导航栏或者底部导航tabBar中的vue-router在3.0版本以上频繁点击菜单报错的问题。
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Manager',
    component: () => import('../views/Manager.vue'),
    redirect: '/home',  // 重定向到主页
    children: [
      { path: 'test1', name: 'Test1', meta: { name: '测试信息' }, component: () => import('../views/manager/test1') },
      { path: '403', name: 'NoAuth', meta: { name: '无权限' }, component: () => import('../views/manager/403') },
      { path: 'home', name: 'Home', meta: { name: '系统首页' }, component: () => import('../views/manager/Home') },
      { path: 'admin', name: 'Admin', meta: { name: '管理员信息' }, component: () => import('../views/manager/Admin') },
      { path: 'adminPerson', name: 'AdminPerson', meta: { name: '个人信息' }, component: () => import('../views/manager/AdminPerson') },
      { path: 'password', name: 'Password', meta: { name: '修改密码' }, component: () => import('../views/manager/Password') },
      { path: 'notice', name: 'Notice', meta: { name: '公告信息' }, component: () => import('../views/manager/Notice') },
      { path: 'Staff', name: 'Staff', meta: { name: '员工信息' }, component: () => import('../views/manager/Staff') },
      { path: 'staffPerson', name: 'StaffPerson', meta: { name: '员工个人信息' }, component: () => import('../views/manager/StaffPerson') },
      { path: 'department', name: 'Department', meta: { name: '部门信息' }, component: () => import('../views/manager/Department') },
      { path: 'supplier', name: 'Supplier', meta: { name: '供应商信息' }, component: () => import('../views/manager/Supplier') },
      { path: 'customer', name: 'Customer', meta: { name: '客户信息' }, component: () => import('../views/manager/Customer') },
      { path: 'goods', name: 'Goods', meta: { name: '商品信息' }, component: () => import('../views/manager/Goods') },
      { path: 'purchase', name: 'Purchase', meta: { name: '进货信息' }, component: () => import('../views/manager/Purchase') },
      { path: 'goodsList1', name: 'GoodsList1', meta: { name: '主进货清单1' }, component: () => import('../views/manager/GoodsList1') },
      { path: 'back', name: 'Back', meta: { name: '退货信息' }, component: () => import('../views/manager/Back') },
      { path: 'sale', name: 'Sale', meta: { name: '销售信息' }, component: () => import('../views/manager/Sale') },
      { path: 'saleBack', name: 'SaleBack', meta: { name: '销售退货信息' }, component: () => import('../views/manager/SaleBack') },
      { path: 'logs', name: 'Logs', meta: { name: '日志信息' }, component: () => import('../views/manager/Logs') },
      { path: 'stock1', name: 'Stock1', meta: { name: 'MaxVert变频器' }, component: () => import('../views/manager/Stock1') },
      { path: 'stock2', name: 'Stock2', meta: { name: 'NE变频器' }, component: () => import('../views/manager/Stock2') },
      { path: 'stock3', name: 'Stock3', meta: { name: '其它变频' }, component: () => import('../views/manager/Stock3') },
      { path: 'stock4', name: 'Stock4', meta: { name: '变频器配件' }, component: () => import('../views/manager/Stock4') },
      { path: 'stock5', name: 'Stock5', meta: { name: '其它' }, component: () => import('../views/manager/Stock5') },
      { path: 'stock6', name: 'Stock6', meta: { name: '艾默生卡件' }, component: () => import('../views/manager/Stock6') },
      { path: 'stock7', name: 'Stock7', meta: { name: '办公用品' }, component: () => import('../views/manager/Stock7') },
      { path: 'stock8', name: 'Stock8', meta: { name: '待定' }, component: () => import('../views/manager/Stock8') },
      { path: 'project', name: 'Project', meta: { name: '项目报备' }, component: () => import('../views/manager/project') },
      { path: 'goodsList2', name: 'GoodsList2', meta: { name: '主销售清单1' }, component: () => import('../views/manager/GoodsList2') },
      { path: 'maintain', name: 'Maintain', meta: { name: '出入清单' }, component: () => import('../views/manager/Maintain') },
    ]
  },
  {
    path: '/front',
    name: 'Front',
    component: () => import('../views/Front.vue'),
    children: [
      { path: 'home1', name: 'Home1', meta: { name: '系统首页' }, component: () => import('../views/front/Home1') },
      { path: 'person', name: 'Person', meta: { name: '个人信息' }, component: () => import('../views/front/Person') },
    ]
  },
  { path: '/login', name: 'Login', meta: { name: '登录' }, component: () => import('../views/Login.vue') },
  { path: '/register', name: 'Register', meta: { name: '注册' }, component: () => import('../views/Register.vue') },
  { path: '*', name: 'NotFound', meta: { name: '无法访问' }, component: () => import('../views/404.vue') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 注：不需要前台的项目，可以注释掉该路由守卫
// 路由守卫
 router.beforeEach((to ,from, next) => {
   let user = JSON.parse(localStorage.getItem("xm-user") || '{}');
   if (to.path === '/') {
     if (user.role) {
       if (user.role === 'USER') {
         next('/front/home')
       } else {
         next('/home')
       }
     } else {
       next('/login')
     }
   } else {
     next()
   }
 })

export default router
